import { faBadgeCheck, faBriefcase, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { AvatarV2 as Avatar, Chip, Tab } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useStore } from '@feathr/extender/state';

import PersonDetail from './PersonDetail';

import * as styles from './PersonPage.css';

function PersonPage(): JSX.Element {
  const { Persons } = useStore();
  const { t } = useTranslation();
  const { personId } = useParams<{ personId: string }>();
  const person = Persons.get(personId);

  if (!person.isPending && person.get('companies') && !Array.isArray(person.get('companies'))) {
    person.set({ companies: ([] as string[]).concat(person.get('companies')) });
  }

  const tabs = [
    <Tab key={'activity'} link={'activity'} title={t('Activity feed')} />,
    <Tab key={'profile'} link={'profile'} title={t('Profile')} />,
    <Tab key={'custom'} link={'custom-fields'} title={t('Custom fields')} />,
    <Tab key={'subscriptions'} link={'subscriptions'} title={t('Subscriptions')} />,
  ];

  const email = person.get('email');
  const occupation = person.get('occupation');

  const description = (function (): JSX.Element | null {
    if (email || occupation || person.reachable) {
      return (
        <div>
          {(email || occupation) && (
            <div className={styles.descriptionElements}>
              {email && (
                <span>
                  <FontAwesomeIcon icon={faEnvelope} />
                  {email}
                </span>
              )}
              {occupation && (
                <span>
                  <FontAwesomeIcon icon={faBriefcase} />
                  {occupation}
                </span>
              )}
            </div>
          )}
          {person.reachable && (
            <Chip
              className={styles.chip}
              prefix={<FontAwesomeIcon icon={faBadgeCheck} />}
              theme={'green'}
            >
              {t('Reachable')}
            </Chip>
          )}
        </div>
      );
    }

    return null;
  })();

  return (
    <Page
      collapseHeader={false}
      collapseHeaderOnScroll={true}
      description={description}
      loading={person.isPending}
      prefix={
        <Avatar
          className={styles.avatar}
          name={person.get('name')}
          placeholder={person.get('placeholder')}
          size={'large'}
        />
      }
      tabs={tabs}
      title={person.name}
      width={'wide'}
    >
      <PersonDetail person={person} />
    </Page>
  );
}

export default observer(PersonPage);
