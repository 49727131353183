import type { ReactNode } from 'react';
import React from 'react';

import AccountLabel from './AccountLabel';
import BillingConfigurationLabel from './BillingConfigurationLabel';
import CampaignLabel from './CampaignLabel';
import ConversionLabel from './ConversionLabel';
import CreativeLabel from './CreativeLabel';
import CustomFieldLabel from './CustomFieldLabel';
import DataRequestLabel from './DataRequestLabel';
import DomainLabel from './DomainLabel';
import EmailLabel from './EmailLabel';
import EventLabel from './EventLabel';
import FlightLabel from './FlightLabel';
import FontLabel from './FontLabel';
import FormLabel from './FormLabel';
import ImportLabel from './ImportLabel';
import MessageLabel from './MessageLabel';
import PartnerLabel from './PartnerLabel';
import PersonLabel from './PersonLabel';
import SegementLabel from './SegementLabel';
import TemplateLabel from './TemplateLabel';
import UserLabel from './UserLabel';
import UserRoleLabel from './UserRoleLabel';
export interface IMatchProps {
  accountId: string;
  primary: string;
  secondary?: string;
  tertiary?: string;
  quaternary?: string;
  quinary?: string;
  senary?: string;
  septenary?: string;
  octonary?: string;
}

type TLabelFunc = (id: string, props: IMatchProps) => ReactNode;

export interface IRoute {
  /* Label for route. */
  label: ReactNode | TLabelFunc;
  /* Path override for route. */
  path?: (props: IMatchProps) => string;
  children?: Record<string, IRoute>;
}

/**
 * Helper function to fetch label.
 */
export function label(route: IRoute, id: string, props: IMatchProps): ReactNode {
  if (!route) {
    // No route was defined; return capitalized id.
    return id.charAt(0).toUpperCase() + id.slice(1);
  }
  return typeof route.label === 'function' ? route.label(id, props) : route.label;
}

/**
 * Helper function to fetch path.
 *
 * @param route
 * @param limit
 * @param props
 */
export function path(route: IRoute, limit: number, props: IMatchProps): string {
  return typeof route.path === 'function'
    ? route.path(props)
    : `/${Object.values(props)
        // First item is accountId; skip it.
        .slice(1, limit + 1)
        .filter((value) => value)
        .join('/')}`;
}

const creativesRoutes: Record<string, IRoute> = {
  any: {
    label: (id) => <CreativeLabel id={id} />,
  },
};

const templatesRoutes: Record<string, IRoute> = {
  any: {
    label: (id) => <TemplateLabel id={id} />,
  },
  global: {
    label: 'Saved Templates',
  },
  campaign: {
    label: 'From Campaigns',
  },
};

const formsRoutes: Record<string, IRoute> = {
  any: {
    label: (id) => <FormLabel id={id} />,
  },
};

const partnersRoutes: Record<string, IRoute> = {
  any: {
    label: (id) => <PartnerLabel id={id} />,
  },
  activity: {
    label: 'Activity',
  },
  messages: {
    label: 'Messages',
    children: {
      any: {
        label: (id) => <MessageLabel id={id} />,
      },
    },
  },
  imports: {
    label: 'Imports',
    children: {
      any: {
        label: (id) => <ImportLabel id={id} />,
      },
    },
  },
  exports: {
    label: 'Exports',
  },
  fields: {
    label: 'Custom Fields',
  },
  dashboard: {
    label: 'Dashboard Settings',
  },
};

const flightsRoutes: Record<string, IRoute> = {
  any: {
    label: (id) => <FlightLabel id={id} />,
    children: {
      add: {
        label: 'Add',
      },
      campaigns: {
        label: null,
        children: {
          any: {
            label: (id) => <CampaignLabel id={id} />,
            children: {
              edit: {
                label: 'Edit',
              },
            },
          },
        },
      },
      edit: {
        label: 'Edit',
      },
      // Report is in the tab bar
      report: {
        label: null,
      },
      templates: {
        label: (_, { octonary }) => (octonary ? <>Templates</> : null),
        children: templatesRoutes,
      },
    },
  },
  add: {
    label: 'Flight Paths',
  },
};

const eventSettingsRoutes: Record<string, IRoute> = {
  info: {
    label: 'Project Information',
  },
  billing: {
    label: 'Billing',
  },
};

const campaignsRoutes: Record<string, IRoute> = {
  all: {
    label: 'All Marketing',
  },
  ads: {
    label: 'Ads',
  },
  email: {
    label: 'Email Marketing',
  },
  'google-ads': {
    label: 'Google Ads',
  },
  monetization: {
    label: 'Monetization',
  },
  other: {
    label: 'Other Marketing',
  },
  any: {
    label: (id) => <CampaignLabel id={id} />,
    children: {
      edit: {
        label: 'Edit',
      },
      conversions: {
        label: 'Conversions',
        children: {
          any: {
            label: (id) => <ConversionLabel id={id} />,
          },
        },
        path: ({ primary, secondary, tertiary, quaternary }) =>
          `/${primary}/${secondary}/${tertiary}/${quaternary}`,
      },
      templates: {
        label: (_, { senary }) => (senary ? <>Templates</> : null),
        children: templatesRoutes,
      },
    },
  },
};

const marketingRoutes: Record<string, IRoute> = {
  all: {
    label: 'All',
  },
  ads: {
    label: 'Ads',
  },
  email: {
    label: 'Email',
  },
  'google-ads': {
    label: 'Google Ads',
  },
  monetization: {
    label: 'Monetization',
  },
  other: {
    label: 'Other',
  },
};

const eventRoutes: Record<string, IRoute> = {
  any: {
    label: (id) => <EventLabel id={id} />,
    path: ({ primary, secondary }) => `/${primary}/${secondary}/campaigns`,
    children: {
      campaigns: {
        label: 'Campaigns',
        children: campaignsRoutes,
      },
      'google-ads': {
        label: 'Google Ads',
      },
      flights: {
        label: 'Flights',
        children: flightsRoutes,
      },
      partners: {
        label: 'Partners',
        children: partnersRoutes,
      },
      content: {
        label: 'Content',
        path: ({ primary, secondary, tertiary }) =>
          `/${primary}/${secondary}/${tertiary}/creatives`,
        children: {
          creatives: {
            label: 'Creatives',
            children: creativesRoutes,
          },
          templates: {
            label: 'Templates',
            children: {
              any: {
                label: (id) => <TemplateLabel id={id} />,
                children: {
                  edit: {
                    label: 'Edit',
                  },
                },
              },
              // "Saved Templates" in the tab bar
              global: {
                label: null,
              },
              // "From Campaigns" in the tab bar
              campaign: {
                label: null,
              },
            },
          },
          forms: {
            label: 'Forms',
            children: formsRoutes,
          },
        },
      },
      report: {
        label: 'Report',
      },
      settings: {
        label: 'Settings',
        path: ({ primary, secondary, tertiary }) => `/${primary}/${secondary}/${tertiary}/info`,
        children: eventSettingsRoutes,
      },
    },
  },
};

const dataTabs: Record<string, IRoute> = {
  // "People" in the tab bar
  people: {
    label: null,
  },
  // "Activity" in the tab bar
  activity: {
    label: null,
  },
};

const dataRoutes: Record<string, IRoute> = {
  'custom-fields': {
    label: 'Custom Fields',
    children: {
      any: {
        label: (id) => <CustomFieldLabel id={id} />,
        path: ({ primary, secondary, tertiary }) => `/${primary}/${secondary}/${tertiary}/edit`,
      },
    },
  },
  explore: {
    label: 'People',
    children: {
      all: {
        label: 'All',
        children: dataTabs,
      },
      known: {
        label: 'Known',
        children: dataTabs,
      },
      anonymous: {
        label: 'Anonymous',
        children: dataTabs,
      },
    },
  },
  people: {
    label: 'People',
    children: {
      any: {
        label: (id) => <PersonLabel id={id} />,
      },
    },
  },
  imports: {
    label: 'Imports',
    children: {
      any: {
        label: (id) => <ImportLabel id={id} />,
      },
    },
  },
  pixel: {
    label: 'Super Pixel',
    children: {
      implementations: {
        label: 'Implementations',
        children: {
          any: {
            label: (id) => <DataRequestLabel id={id} />,
            path: ({ primary, secondary, tertiary }) =>
              `/${primary}/${secondary}/${tertiary}/implementations`,
          },
        },
      },
    },
  },
  segments: {
    label: 'Groups',
    children: {
      any: {
        label: (id) => <SegementLabel id={id} />,
        path: ({ primary, secondary, tertiary }) => `/${primary}/${secondary}/${tertiary}/people`,
        children: {
          ...dataTabs,
          analytics: {
            // "Analytics" in the tab bar
            label: null,
          },
          settings: {
            // "Settings" in the tab bar
            label: null,
          },
          edit: {
            label: 'Edit',
          },
        },
      },
    },
  },
};

const accountSettingsRoutes: Record<string, IRoute> = {
  advertisers: {
    label: 'Advertisers',
    children: {
      any: {
        label: (id, props) => <AccountLabel id={id} matches={props} />,
        path: ({ primary, secondary, tertiary, quaternary }) =>
          `/${primary}/${secondary}/${tertiary}/${quaternary}`,
      },
    },
  },
  'allow-list': {
    label: 'Domain Allow List',
    children: {
      any: {
        label: 'Allow List',
        path: ({ primary, secondary, tertiary, quaternary }) =>
          `/${primary}/${secondary}/${tertiary}/${quaternary}`,
      },
    },
  },
  conversions: {
    label: 'Conversions',
  },
  domains: {
    label: 'Domains',
    children: {
      any: {
        label: (id) => <DomainLabel id={id} />,
        path: ({ primary, secondary, tertiary, quaternary }) =>
          `/${primary}/${secondary}/${tertiary}/${quaternary}`,
        children: {
          edit: {
            label: 'Edit',
          },
        },
      },
    },
  },
  emails: {
    label: 'Email Addresses',
    children: {
      any: {
        label: (id) => <EmailLabel id={id} />,
        path: ({ primary, secondary, tertiary, quaternary }) =>
          `/${primary}/${secondary}/${tertiary}/${quaternary}/edit`,
      },
    },
  },
  'ip-filtering': {
    label: 'IP Filtering',
  },
  fonts: {
    label: 'Fonts',
    children: {
      any: {
        label: (id) => <FontLabel id={id} />,
        path: ({ primary, secondary, tertiary, quaternary }) =>
          `/${primary}/${secondary}/${tertiary}/${quaternary}/edit`,
      },
    },
  },
  profile: {
    label: 'Profile',
  },
  general: {
    label: 'General',
  },
  subscriptions: {
    label: 'Subscriptions',
  },
  users: {
    // label: 'Users & Roles',
    label: (id) => <UserRoleLabel id={id} />,
    children: {
      // "Authentication" in the tab bar
      authentication: {
        label: null,
      },
      // "Roles" in the tab bar
      roles: {
        label: (_, props) => {
          // Only show "Roles" in the breadcrumbs in a nested page of the "Roles" tab
          return props?.quinary ? 'Roles' : null;
        },
        children: {
          any: {
            label: (id) => <UserRoleLabel id={id} />,
          },
        },
      },
      invite: {
        label: 'Invite Users',
      },
      any: {
        label: (id) => <UserLabel id={id} />,
        path: ({ primary, secondary, tertiary, quaternary }) =>
          `/${primary}/${secondary}/${tertiary}/${quaternary}/edit`,
      },
    },
  },
};

const userSettingsRoutes: Record<string, IRoute> = {
  'reset-password': {
    label: 'Reset Password',
  },
  subscriptions: {
    label: 'Subscriptions',
  },
  profile: {
    label: 'Profile',
  },
  notifications: {
    label: 'Notifications',
  },
};

const integrationsSettingsRoutes: Record<string, IRoute> = {
  'api-keys': {
    label: 'API Keys',
  },
  'raisers-edge': {
    label: "Raiser's Edge NXT",
  },
  imis: {
    label: 'iMIS',
  },
  meta: {
    label: 'Meta',
  },
  'google-ads': {
    label: 'Google Ads',
  },
};

const billingSettingsRoutes: Record<string, IRoute> = {
  configurations: {
    label: 'Configurations',
    children: {
      any: {
        label: (id) => <BillingConfigurationLabel id={id} />,
        path: ({ primary, secondary, tertiary, quaternary }) =>
          `/${primary}/${secondary}/${tertiary}/${quaternary}/edit`,
      },
    },
  },
  license: {
    label: 'License',
    children: {
      edit: {
        label: 'Edit',
      },
    },
  },
  invoices: {
    label: 'Invoices',
  },
};

const settingsRoutes: Record<string, IRoute> = {
  user: {
    label: 'User',
    children: userSettingsRoutes,
  },
  account: {
    label: 'Account',
    children: accountSettingsRoutes,
  },
  integrations: {
    label: 'Integrations',
    children: integrationsSettingsRoutes,
  },
  billing: {
    label: 'Billing',
    children: billingSettingsRoutes,
  },
  'pro-resources': {
    label: 'Resources',
  },
};

const routes: Record<string, IRoute> = {
  accounts: {
    label: 'Accounts',
  },
  data: {
    label: 'Community',
    children: dataRoutes,
  },
  marketing: {
    label: 'Marketing',
    children: marketingRoutes,
  },
  notifications: {
    label: 'Notifications',
  },
  projects: {
    label: 'Projects',
    children: eventRoutes,
  },

  settings: {
    label: 'Settings',
    children: settingsRoutes,
  },
  templates: {
    label: 'Templates',
    children: templatesRoutes,
  },
};

/**
 * @param match
 * @param depth 1 for primary; 2 for secondary; etc.
 */
export function getRoute(match: IMatchProps, depth: number): IRoute | null {
  const matchMap: Record<number, keyof IMatchProps> = {
    0: 'accountId',
    1: 'primary',
    2: 'secondary',
    3: 'tertiary',
    4: 'quaternary',
    5: 'quinary',
    6: 'senary',
    7: 'septenary',
    8: 'octonary',
  };
  const matchCount = Math.min(depth + 1, Object.keys(matchMap).length);

  function routeChild(route: IRoute, child: keyof IMatchProps): IRoute | null {
    if (!route.children) {
      return null;
    }
    const key = match[child]!;
    return route.children[key]
      ? route.children[key]
      : route.children.any
        ? route.children.any
        : null;
  }

  let map: IRoute | null = {
    label: 'Home',
    children: routes,
  };

  // Skip accountId.
  for (let i = 1; i < matchCount; i++) {
    if (map === null) {
      return null;
    }
    map = routeChild(map, matchMap[i]);
  }

  return map;
}

export default routes;
