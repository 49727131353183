import type { IObservableArray } from 'mobx';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import type {
  Account,
  Billable,
  DisplayCreative,
  Event,
  Goal,
  Partner,
  Segment,
  Targetable,
  Targeting,
  TTDCampaign,
  User,
} from '@feathr/blackbox';
import { CampaignClass, CampaignState } from '@feathr/blackbox';
import { ActionBar, Button, ButtonValid, Spinner, Step, Steps, Wizard } from '@feathr/components';
import { useCampaignText } from '@feathr/extender/hooks';
import { useAccount, useFlags, useLocalUrl, useStore, useUser } from '@feathr/extender/state';
import { flattenErrors, getIconForAction } from '@feathr/hooks';
import type { ListResponse, Model } from '@feathr/rachis';

import type { ICampaignValidationErrors } from '../../CampaignSummary';
import createCampaignStore, { CampaignContext } from '../CampaignEditPage.context';
import SaveCampaignButton, { save } from '../SaveCampaignButton';
import StepGoals, { getGoalSegments, validateStepGoals } from '../StepGoals';
import { validateStepValue } from '../StepValue';
import WizardGeneralStep, { validateStepGeneral } from '../WizardGeneralStep';
import AdWizardBudgetStep, { validateStepBudget } from './AdWizardBudgetStep';
import AdWizardCreativesStep, { validateStepCreatives } from './AdWizardCreativesStep';
import AdWizardFiltersStep, { getGeoFilters, validateStepFilters } from './AdWizardFiltersStep';
import AdWizardOptimizationStep, { validateStepOptimize } from './AdWizardOptimizationStep';
import AdWizardReviewStep from './AdWizardReviewStep';
import { validatePartner } from './AdWizardReviewStep/AdWizardReviewStep';
import AdWizardTargetsStep, {
  getTargetables,
  getTargetSegments,
  validateStepTargets,
} from './AdWizardTargetsStep';

interface IProps {
  campaign: TTDCampaign;
}

export interface ICampaignDetails {
  campaign: TTDCampaign;
  targetings: IObservableArray<Targeting>;
  targetingSegments?: Segment[];
  targetables?: Targetable[];
  goals: IObservableArray<Goal>;
  goalSegments?: Segment[];
  creatives: IObservableArray<DisplayCreative>;
  billable?: Billable;
  account: Account;
  event: Event;
  monetizationValue?: number;
  partner?: Partner;
}

function validate(
  details: ICampaignDetails,
  user?: User,
  noMaxBudget?: boolean,
): ICampaignValidationErrors {
  const {
    campaign,
    event,
    account,
    billable,
    creatives,
    goals,
    goalSegments,
    targetables,
    targetings,
    targetingSegments,
    partner,
  } = details;

  const segmentsOrTargetables =
    campaign.get('cls') === CampaignClass.Segment ? targetingSegments : targetables;
  const generalStepValidation = validateStepGeneral(campaign);
  const targetsStepValidation = flattenErrors(
    validateStepTargets(campaign, targetings, segmentsOrTargetables),
  );
  const filtersStepValidation = validateStepFilters(targetings);
  const creativesStepValidation = validateStepCreatives(campaign, creatives);
  const budgetStepValidation = validateStepBudget(
    campaign,
    event,
    billable,
    account,
    user,
    noMaxBudget,
  );
  const optimizationStepValidation = validateStepOptimize(campaign);
  const goalsStepValidation = validateStepGoals(goals, goalSegments);

  const monetizationStepValidation = [
    ...(budgetStepValidation.monetization_value ?? []),
    ...(partner ? validatePartner(partner) : []),
  ];

  const validationErrors: ICampaignValidationErrors = {
    name: generalStepValidation,
    targets: targetsStepValidation,
    filters: filtersStepValidation,
    creatives: creativesStepValidation,
    duration: [
      ...(budgetStepValidation.date_start ?? []),
      ...(budgetStepValidation.date_end ?? []),
    ],
    optimization: optimizationStepValidation,
    budget: budgetStepValidation.budget,
    monetization: monetizationStepValidation,
    goals: goalsStepValidation.goals,
  };

  return validationErrors;
}

const getCompletedStep = (
  {
    account,
    campaign,
    creatives,
    billable,
    event,
    goals,
    goalSegments,
    targetings,
    targetingSegments,
    targetables,
  }: ICampaignDetails,
  hideFilters: boolean,
  user?: User,
  noMaxBudget?: boolean,
): number => {
  if (validateStepGeneral(campaign).length) {
    return 0;
  }
  const stepTwoArgThree =
    campaign.get('_cls') === CampaignClass.Segment ? targetingSegments : targetables;
  if (
    targetings.length === 0 ||
    flattenErrors(validateStepTargets(campaign, targetings, stepTwoArgThree)).length
  ) {
    return 1;
  }
  if (!hideFilters && validateStepFilters(targetings).length) {
    return 2;
  }
  if (validateStepCreatives(campaign, creatives).length) {
    return 3;
  }

  if (validateStepOptimize(campaign).length) {
    return 4;
  }
  if (
    flattenErrors(validateStepBudget(campaign, event, billable, account, user, noMaxBudget)).length
  ) {
    return 5;
  }
  if (campaign.isMonetization && flattenErrors(validateStepValue(campaign)).length) {
    return 6;
  } else if (flattenErrors(validateStepGoals(goals, goalSegments)).length) {
    return 6;
  }
  return 7;
};

// TODO: incorporate new wizard hooks here #4560
const getCurrentStepErrors = (
  {
    account,
    campaign,
    creatives,
    billable,
    event,
    goals,
    goalSegments,
    targetings,
    targetingSegments,
    targetables,
    partner,
  }: ICampaignDetails,
  hideFilters: boolean,
  user?: User,
  noMaxBudget?: boolean,
  currentStep?: number,
): string[] => {
  const stepTwoArgThree =
    campaign.get('_cls') === CampaignClass.Segment ? targetingSegments : targetables;

  const map = {
    0: validateStepGeneral(campaign),
    1: flattenErrors(validateStepTargets(campaign, targetings, stepTwoArgThree)),
    2: hideFilters ? [] : validateStepFilters(targetings),
    3: validateStepCreatives(campaign, creatives),
    4: flattenErrors(validateStepOptimize(campaign)),
    5: flattenErrors(validateStepBudget(campaign, event, billable, account, user, noMaxBudget)),
    6: campaign.isMonetization
      ? flattenErrors(validateStepValue(campaign))
      : flattenErrors(validateStepGoals(goals, goalSegments)),
    7: partner ? validatePartner(partner) : [],
  };

  // Fix a bug that made all errors show up instead of just the step specific ones
  if (typeof currentStep === 'number') {
    return map[currentStep];
  }

  const all = Object.values(map).reduce((acc, errors) => {
    if (Array.isArray(errors)) {
      acc.push(...errors);
    }
    return acc;
  }, []);

  return all;
};

function AdWizard({ campaign }: IProps): JSX.Element {
  const { Creatives, Billables, Events, Goals, Segments, Targetings, Targetables, Partners } =
    useStore();
  const account = useAccount();
  const location = useLocation();
  const flags = useFlags();
  const user = useUser();
  const { t } = useTranslation();
  const history = useHistory();
  const localUrl = useLocalUrl();
  const { disableStopOrRepublishPastEndDate } = useCampaignText();

  const { isDraft, isGeofencingCampaign, isStopped, isPublishing, isPastEndDate } = campaign;
  const hideFilters = isGeofencingCampaign;

  const foundStep = /#step(\d+)$/.exec(location.hash);
  const defaultStep = foundStep ? +foundStep[1] - 1 : undefined;

  const [currentStep, setCurrentStep] = useState(defaultStep ?? 0);
  const [completedStep, setCompletedStep] = useState<number | undefined>(defaultStep);
  const [didCompleteStep, setDidCompleteStep] = useState<boolean>(false);

  const partnerId = campaign.isMonetization ? campaign.get('parent') : undefined;
  const partner = partnerId ? Partners.get(partnerId) : undefined;

  const event = Events.get(campaign.get('event'));
  const billing = event.get('billing');
  const billable =
    !event.isPending && billing && billing.billable_id
      ? Billables.get(billing.billable_id)
      : undefined;

  const targetings = Targetings.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
    pagination: { page_size: 1000 },
  });
  const goals = Goals.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });
  // Campaigns only have DisplayCreatives.
  const creatives = Creatives.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  }) as ListResponse<DisplayCreative>;

  const doCompleteStep = (): void => {
    if (
      !event.isPending &&
      !targetings.isPending &&
      !goals.isPending &&
      !creatives.isPending &&
      !didCompleteStep
    ) {
      const goalSegments = getGoalSegments(goals.models, Segments);
      const targetingSegments = getTargetSegments(targetings.models, Segments);
      const targetables = getTargetables(targetings.models, Targetables);
      Promise.all([
        ...goalSegments.map((s) => when(() => !s.isPending)),
        ...targetingSegments.map((s) => when(() => !s.isPending)),
        ...targetables.map((t) => when(() => !t.isPending)),
      ]).then(() => {
        const step = getCompletedStep(
          {
            campaign,
            targetings: targetings.models,
            targetingSegments: getTargetSegments(targetings.models, Segments),
            targetables: getTargetables(targetings.models, Targetables),
            goals: goals.models,
            goalSegments: getGoalSegments(goals.models, Segments),
            creatives: creatives.models,
            billable,
            account,
            event,
            partner,
          },
          hideFilters,
          user,
          flags.noMaxBudget,
        );
        setCurrentStep(defaultStep || step);
        setCompletedStep(step);
        setDidCompleteStep(true);
      });
    }
  };
  useEffect(doCompleteStep, [
    account,
    billable,
    billable?.isPending,
    campaign,
    creatives.isPending,
    creatives.models,
    defaultStep,
    didCompleteStep,
    event,
    event.isPending,
    flags.noMaxBudget,
    goals.isPending,
    goals.models,
    hideFilters,
    Segments,
    Targetables,
    targetings.isPending,
    targetings.models,
    user,
    partner,
  ]);

  function onNext(): void {
    const nextStep = currentStep + (hideFilters && currentStep === 1 ? 2 : 1);
    setCurrentStep(nextStep);
    if (nextStep > (completedStep ?? 0)) {
      setCompletedStep(nextStep);
    }
  }

  function onPrev(): void {
    const prevStep = currentStep - (hideFilters && currentStep === 3 ? 2 : 1);
    setCurrentStep(prevStep);
  }

  const isLoading =
    campaign.isPending ||
    goals.isPending ||
    event.isPending ||
    targetings.isPending ||
    creatives.isPending ||
    currentStep === -1 ||
    completedStep === undefined;

  if (isLoading) {
    return <Spinner />;
  }

  const stepsItems = [
    <Step key={0} stepIndex={0} title={'General'} />,
    <Step key={1} stepIndex={1} title={'Targets'} />,
    <Step key={2} stepIndex={2} title={'Filters (optional)'} />,
    <Step key={3} stepIndex={3} title={'Creatives'} />,
    <Step key={4} stepIndex={4} title={'Optimization'} />,
    <Step key={5} stepIndex={5} title={'Budget'} />,
    <Step key={6} stepIndex={6} title={'Goals'} />,
    <Step key={7} stepIndex={7} title={'Review'} />,
  ];
  if (hideFilters) {
    // Remove filters step
    stepsItems.splice(2, 1);
  }
  // The index is still the same if we splice out a step because the onNext and onPrevious methods account for it
  const lastStep = 7;

  const steps = (
    <Steps
      completed={completedStep}
      current={currentStep}
      onChange={setCurrentStep}
      usePortal={true}
    >
      {stepsItems}
    </Steps>
  );

  const grandchildModels: Model[] = [
    ...getTargetSegments(targetings.models, Segments),
    ...getGoalSegments(goals.models, Segments),
    ...getGeoFilters(targetings.models, Targetables),
  ];
  // Converting observables back to vanilla JavaScript.
  const childModels: Model[] = [
    ...getTargetables(targetings.models, Targetables),
    ...targetings.models.slice(),
    ...creatives.models.slice(),
    ...goals.models.slice(),
  ];

  function buttonValidate(): ICampaignValidationErrors {
    const targetingSegments = getTargetSegments(targetings.models, Segments);
    const targetables = getTargetables(targetings.models, Targetables);
    const goalSegments = getGoalSegments(goals.models, Segments);
    return validate(
      {
        account,
        billable,
        campaign,
        creatives: creatives.models,
        event,
        goals: goals.models,
        goalSegments,
        targetables,
        targetings: targetings.models,
        targetingSegments,
        partner,
      },
      user,
      flags.noMaxBudget,
    );
  }

  function getErrors(step?: number): string[] {
    return getCurrentStepErrors(
      {
        campaign,
        targetings: targetings.models,
        targetingSegments: getTargetSegments(targetings.models, Segments),
        targetables: getTargetables(targetings.models, Targetables),
        goals: goals.models,
        goalSegments: getGoalSegments(goals.models, Segments),
        creatives: creatives.models,
        billable,
        account,
        event,
        partner,
      },
      hideFilters,
      user,
      flags.noMaxBudget,
      step,
    );
  }

  const campaignStore = createCampaignStore({ targetings });
  const stepSpecificErrors = getErrors(currentStep);
  const allErrors = getErrors();

  async function handlePublish(): Promise<void> {
    await save({
      campaign,
      childModels,
      grandchildModels,
      shouldChangeState: true,
      t,
      accountId: account.id,
    });
    history.push(localUrl(campaign.getItemUrl()));
  }

  return (
    <CampaignContext.Provider value={campaignStore}>
      <ActionBar
        left={
          <>
            <Button
              disabled={currentStep <= 0 || isLoading}
              onClick={onPrev}
              prefix={getIconForAction('previous')}
            >
              {t('Previous')}
            </Button>
            <ButtonValid
              disabled={currentStep === lastStep || isLoading || undefined}
              errors={stepSpecificErrors}
              name={'next_step'}
              onClick={onNext}
              suffix={getIconForAction('next')}
              type={isDraft ? 'primary' : 'secondary'}
            >
              {t('Next')}
            </ButtonValid>
          </>
        }
        right={
          <>
            <SaveCampaignButton
              campaign={campaign}
              childModels={childModels}
              grandchildModels={grandchildModels}
              key={'save'}
              shouldChangeState={false}
              showIcon={true}
              // We shouldn't allow saving invalid content to a published/stopped campaign
              validate={
                [CampaignState.Published, CampaignState.Publishing, CampaignState.Stopped].includes(
                  campaign.get('state'),
                )
                  ? buttonValidate
                  : undefined
              }
            />
            {(isDraft || isStopped) && (
              <ButtonValid
                disabled={completedStep < 6 || isPublishing || isPastEndDate || undefined}
                errors={allErrors}
                name={'publish'}
                onClick={handlePublish}
                prefix={getIconForAction('publish')}
                tooltip={isPastEndDate ? disableStopOrRepublishPastEndDate : undefined}
                tooltipPosition={'top-end'}
                type={'success'}
              >
                {t('Publish')}
              </ButtonValid>
            )}
          </>
        }
        usePortal={true}
      />
      <div data-appcues-campaign={campaign.get('_cls')}>
        <Wizard isFullWidth={true} layout={'horizontal'} steps={steps}>
          {currentStep === 0 && <WizardGeneralStep campaign={campaign} onNext={onNext} />}
          {currentStep === 1 && (
            <AdWizardTargetsStep
              campaign={campaign}
              onNext={onNext}
              onPrev={onPrev}
              targetings={targetings.models}
            />
          )}
          {currentStep === 2 && (
            <AdWizardFiltersStep campaign={campaign} targetings={targetings.models} />
          )}
          {currentStep === 3 && (
            <AdWizardCreativesStep campaign={campaign} creatives={creatives.models} />
          )}
          {currentStep === 4 && (
            <AdWizardOptimizationStep campaign={campaign} targetings={targetings.models} />
          )}
          {currentStep === 5 && (
            <AdWizardBudgetStep
              account={account}
              billable={billable}
              campaign={campaign}
              event={event}
              onNext={onNext}
              onPrev={onPrev}
              targetings={targetings.models}
            />
          )}
          {currentStep === 6 && (
            <StepGoals campaign={campaign} goals={goals.models} onNext={onNext} onPrev={onPrev} />
          )}
          {currentStep === 7 && (
            <AdWizardReviewStep
              account={account}
              billable={billable}
              campaign={campaign}
              creatives={creatives.models}
              event={event}
              goals={goals.models}
              targets={targetings.models}
              validate={validate}
            />
          )}
        </Wizard>
      </div>
    </CampaignContext.Provider>
  );
}

export default observer(AdWizard);
