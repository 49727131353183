import { faCheck, faExpand, faFileCheck, faShareNodes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type * as H from 'history';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useParams } from 'react-router';
import { ToastType } from 'react-toastify';

import type { Form } from '@feathr/blackbox';
import {
  ActionBar,
  AlertV2 as Alert,
  Button,
  ButtonValid,
  Chip,
  EAlertV2Type,
  ETheme,
  Icon,
  Label,
  SaveButtonValid,
  Tab,
  Time,
  toast,
} from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { formStateLabelMap, formStateThemeMap } from '@feathr/extender/styles/forms';
import { errorMessage, flattenErrors, TimeFormat, useToggle } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import Page from '../../Page';
import FormContextMenu from './FormContextMenu';
import { FormEditor } from './FormEditor';
import FormReport from './FormReport';
import Settings from './FormSettings';
import FormShareModal from './FormShareModal';

import * as styles from './FormPage.css';

type TTab = 'report' | 'edit' | 'settings';

function validateForm(form: Form): TValidateGrouped {
  return (
    form.validate(
      [
        'settings.post_submit_html',
        'settings.post_submit_redirect_url',
        'notification_settings.email_addresses',
      ],
      true,
      'grouped',
    ).errors ?? []
  );
}

function FormPage(): JSX.Element {
  const { formId, tab } = useParams<{ formId?: string; tab?: TTab }>();

  const { Forms } = useStore();
  const [isShareModalOpen, toggleShareModal] = useToggle(false);
  const [isFullScreen, toggleFullScreen] = useToggle(false);

  if (!formId) {
    throw new Error('Form missing ID');
  }

  const { t } = useTranslation();
  const form = Forms.get(formId);

  const {
    date_last_published: lastPublished,
    date_last_modified: lastSaved,
    version,
    last_published_version: lastPublishedVersion,
    state,
  } = form.get([
    'date_last_published',
    'date_last_modified',
    'version',
    'last_published_version',
    'state',
  ]);
  const hasVersionDifference = version !== lastPublishedVersion;
  const [activeTab, setActiveTab] = useState<TTab>(tab ?? (form.published ? 'report' : 'edit'));
  const [isLoading, setIsLoading] = useState(false);
  const validationErrorsForm = validateForm(form);
  const [hasValidationErrors, setHasValidationErrors] = useState(false);

  useEffect(() => {
    // Reset changed model if they weren't saved so
    // the user doesn't see the changes if they navigate away
    // and back to this page.
    return () => {
      if (form.isDirty) {
        form.discardDirty();
      }
    };
  }, [form]);

  function handleClickReportTab(): void {
    setActiveTab('report');
  }

  function handleClickEditTab(): void {
    setActiveTab('edit');
  }

  function handleClickSettingsTab(): void {
    setActiveTab('settings');
  }

  function handleValidationChange(hasErrors: boolean): void {
    setHasValidationErrors(hasErrors);
  }

  const tabs = [
    <Tab
      active={activeTab === 'edit'}
      key={'edit'}
      link={'edit'}
      onClick={handleClickEditTab}
      title={t('Edit')}
    />,
    <Tab
      active={activeTab === 'settings'}
      key={'settings'}
      link={'settings'}
      onClick={handleClickSettingsTab}
      title={t('Settings')}
    />,
  ];

  if (form.published) {
    tabs.unshift(
      <Tab
        active={activeTab === 'report'}
        key={'report'}
        link={'report'}
        onClick={handleClickReportTab}
        title={t('Report')}
      />,
    );
  }

  const actions: JSX.Element[] = [<FormContextMenu form={form} key={'form-context-menu'} />];

  if (form.published) {
    actions.push(
      <Button
        key={'share'}
        onClick={toggleShareModal}
        prefix={<Icon icon={faShareNodes} />}
        type={'secondary'}
      >
        {t('Share')}
      </Button>,
    );
  }

  if (activeTab === 'edit') {
    actions.push(
      <Button key={'full-screen'} onClick={toggleFullScreen} prefix={<Icon icon={faExpand} />}>
        {t('Full Screen')}
      </Button>,
    );
  }

  function reorderActions(actions: JSX.Element[]): JSX.Element[] {
    // On render, set the order of the actions
    return [
      actions.find((action) => action.key === 'form-context-menu'),
      actions.find((action) => action.key === 'full-screen'),
      actions.find((action) => action.key === 'share'),
    ].filter(Boolean) as JSX.Element[];
  }

  const reorderedActions = reorderActions(actions);

  async function handleRepublish(): Promise<void> {
    setIsLoading(true);
    try {
      await form.publish();
      toast(t('Form updated'), {
        type: ToastType.SUCCESS,
      });
      await form.reload();
      setIsLoading(false);
    } catch (error) {
      toast(t('Error upsyncing form:\n{{- error}}', { error: errorMessage(error, t) }), {
        type: ToastType.ERROR,
      });
      setIsLoading(false);
    }
  }

  const validationMessage = !form.isValid()
    ? flattenErrors(validationErrorsForm)
    : [t('Fix errors to continue')];

  // Disabled if there are validation errors, no fields aside from email used, or no changes made + no version difference
  const isPublishDisabled =
    !form.isValid() || hasValidationErrors || (!hasVersionDifference && !form.isDirty);

  // Casted as any because types for Prompt are not right; we have to pass () => false for the navigation to cancel properly.
  function promptUnsavedChanges(location: H.Location): any {
    if (location.pathname.includes(form.id)) {
      // Prevent prompt if user is on the same page
      return () => false;
    } else {
      return t('You have unsaved changes. Are you sure you want to leave this page?');
    }
  }

  return (
    <Page
      actions={reorderedActions}
      ChipsComponent={
        <div className={styles.chips}>
          {/* Our Icon component does not play nicely with Chip, so we use FontAwesomeIcon instead. */}
          <Chip prefix={<FontAwesomeIcon icon={faFileCheck} />} theme={ETheme.blue}>
            Forms
          </Chip>
          <Chip theme={formStateThemeMap(state)}>{formStateLabelMap(t, state)}</Chip>
        </div>
      }
      collapseHeaderOnScroll={false}
      includeCurrentPageCrumb={true}
      legacyScrollClassName={styles.legacyScroll}
      loading={form.isPending}
      tabs={tabs}
      title={t('Edit {{- name}}', { name: form.name })}
      truncateTitle={true}
    >
      <ActionBar
        left={
          activeTab !== 'report' ? (
            <span className={styles.leftActions}>
              <Label margin={'condensed'} weight={'semibold'}>
                {form.published ? t('Last published:') : t('Last saved:')}
              </Label>
              <Time
                className={styles.lastPublished}
                format={TimeFormat.longDateTime}
                formatLocal={true}
                showTimezone={true}
                timestamp={form.published ? lastPublished : lastSaved}
              />
            </span>
          ) : undefined
        }
        right={
          activeTab !== 'report' ? (
            <>
              <SaveButtonValid
                disabled={!form.isDirty || hasValidationErrors}
                errors={hasValidationErrors || !form.isValid() ? validationMessage : []}
                key={'save'}
                method={'patch'}
                model={form}
                successMessage={t('Your changes have been saved.')}
                type={'secondary'}
              />
              <ButtonValid
                disabled={isPublishDisabled}
                errors={hasValidationErrors || !form.isValid() ? validationMessage : []}
                key={'publish'}
                onClick={handleRepublish}
                prefix={<Icon icon={faCheck} />}
                type={'success'}
              >
                {form.published ? t('Republish') : t('Publish')}
              </ButtonValid>
            </>
          ) : undefined
        }
        usePortal={true}
      />

      {!isLoading && hasVersionDifference && form.published && activeTab !== 'report' && (
        <Alert
          title={t(
            'This version is newer than the published form. Republish to apply your changes.',
          )}
          type={EAlertV2Type.info}
        />
      )}
      <Prompt message={promptUnsavedChanges} when={form.isDirty} />
      {activeTab === 'report' && <FormReport form={form} />}
      {activeTab === 'edit' && (
        <FormEditor
          form={form}
          isFullScreen={isFullScreen}
          onValidationChange={handleValidationChange}
          toggleFullScreen={toggleFullScreen}
        />
      )}
      {activeTab === 'settings' && <Settings form={form} />}

      {isShareModalOpen && (
        <FormShareModal form={form} isOpen={isShareModalOpen} toggle={toggleShareModal} />
      )}
    </Page>
  );
}

export default observer(FormPage);
