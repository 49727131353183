import { useDisclosure } from '@mantine/hooks';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ToastType } from 'react-toastify';

import type { Form } from '@feathr/blackbox';
import { ArchiveModal, ContextMenu, toast } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';
import { errorMessage, getIconForAction, useRedirect } from '@feathr/hooks';

interface IProps {
  form: Form;
}

function FormContextMenu({ form }: IProps): JSX.Element {
  const { t } = useTranslation();

  const { eventId, formId } = useParams<{
    eventId: string;
    formId: string;
  }>();
  const [redirect] = useRedirect();
  const localUrl = useLocalUrl();

  const [showArchiveModal, { open: openArchiveModal, close: closeArchiveModal }] =
    useDisclosure(false);

  async function handleFormDelete(): Promise<void> {
    try {
      await form.delete();
      toast(t('Form "{{- formName}}" has been deleted', { formName: form.name }), {
        type: ToastType.SUCCESS,
      });
      closeArchiveModal();
      // Redirect to forms page if formId is present in URL params
      if (formId) {
        const targetPath = `/projects/${eventId}/content/forms`;
        redirect(localUrl(targetPath));
      }
    } catch (error) {
      toast(
        t('Error deleting form: "{{- formName}}"\n{{- error}}', {
          formName: form.name,
          error: errorMessage(error, t),
        }),
        {
          type: ToastType.ERROR,
        },
      );
    }
  }

  return (
    <>
      <ContextMenu iconName={'ellipsis'} key={'context-menu'}>
        <ContextMenu.Item
          name={'delete-form'}
          onClick={openArchiveModal}
          prefix={getIconForAction('delete')}
          theme={'danger'}
        >
          {t('Delete')}
        </ContextMenu.Item>
      </ContextMenu>
      <ArchiveModal
        cancelButtonText={t('Cancel')}
        confirmationPhrase={t('DELETE')}
        confirmButtonType={'danger'}
        description={t(
          'A deleted form cannot be restored. All data previously captured by this form will persist but it will no longer load in any published location.',
        )}
        method={'delete'}
        onClose={closeArchiveModal}
        onConfirm={handleFormDelete}
        opened={showArchiveModal}
        t={t}
        title={t('Delete form: "{{- formName}}"', { formName: form.name })}
      />
    </>
  );
}

export default FormContextMenu;
