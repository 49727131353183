import { when } from 'mobx';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { IDailyStats } from '@feathr/blackbox';
import type { TTimeFrameValue } from '@feathr/components';
import { useId } from '@feathr/hooks';

import { useStore } from '../state';

interface IUseDashboardStats {
  timeFrameValue: TTimeFrameValue;
  prioritizeVerifiedActivity?: boolean;
}
interface IUseDashboardStatsReturn {
  isLoading: boolean;
  chartData: IDailyStats[];
}

function useDashboardStats({
  timeFrameValue,
  prioritizeVerifiedActivity = true,
}: IUseDashboardStats): IUseDashboardStatsReturn {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<IDailyStats[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);

  const { Stats } = useStore();
  const listId = useId({ ephemeral: true });

  const initStats = useMemo(() => {
    const filters = {
      metadata__date__gte: timeFrameValue[0],
      metadata__date__lte: timeFrameValue[1],
    };

    return Stats.list(
      {
        model: 'account',
        filters,
        include_bots: !prioritizeVerifiedActivity,
      },
      {},
      listId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Stats, timeFrameValue, prioritizeVerifiedActivity]);

  const fetchStats = useCallback(async () => {
    const stats = initStats;
    await when(() => !stats.isPending);

    // Set the page and total pages
    setPage(stats.pagination.page);
    setTotalPages(stats.pagination.pages);

    // Set the chart data
    setChartData(stats.models.map((s) => s.toJS()));
    setIsLoading(false);
  }, [initStats]);

  const fetchMoreStats = useCallback(async () => {
    const stats = initStats;

    // Fetch more stats
    stats.fetchMore();
    await when(() => !stats.isPending);

    // Update the chart data
    setChartData(stats.models.map((s) => s.toJS()));

    // Update the page and total pages
    setPage(stats.pagination.page);
    setTotalPages(stats.pagination.pages);
  }, [initStats]);

  useEffect(() => {
    fetchStats();
  }, [fetchStats, prioritizeVerifiedActivity]);

  useEffect(() => {
    if (page < totalPages) {
      fetchMoreStats();
    }
  }, [fetchMoreStats, page, totalPages]);

  // If `prioritizeVerifiedActivity` is `false`, add `num_bot_email_clicks` to `num_email_clicks`
  // and `num_bot_email_opens` to `num_email_opens` to ensure the charts display the requested data.
  const processedChartData: IDailyStats[] = useMemo(() => {
    return chartData.map((data): IDailyStats => {
      const humanClicks = data.num_email_clicks ?? 0;
      const humanOpens = data.num_email_opens ?? 0;
      const botClicks = data.num_bot_email_clicks ?? 0;
      const botOpens = data.num_bot_email_opens ?? 0;

      return {
        ...data,
        num_email_clicks: prioritizeVerifiedActivity ? humanClicks : humanClicks + botClicks,
        num_email_opens: prioritizeVerifiedActivity ? humanOpens : humanOpens + botOpens,
      };
    });
  }, [chartData, prioritizeVerifiedActivity]);

  return {
    isLoading,
    chartData: processedChartData,
  };
}

export default useDashboardStats;
