import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CampaignState,
  type GoogleAdsCreative,
  type GoogleAdsSmartCampaign,
  type Targeting,
} from '@feathr/blackbox';
import type { IWizardStepsCompleted } from '@feathr/components';
import {
  ActionBar,
  Button,
  ButtonValid,
  Step,
  Steps,
  useWizardState,
  Wizard,
} from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { flattenErrors, useToggle } from '@feathr/hooks';
import type { ListResponse, Model } from '@feathr/rachis';

import SaveCampaignButton from '../../CampaignEditPage/SaveCampaignButton';
import type { ICampaignValidationErrors } from '../../CampaignSummary';
import AdTextStep, { validateAdTextStep } from './AdTextStep';
import BudgetStep, { validateBudgetStep } from './BudgetStep';
import GoalStep, { validateGoalStep } from './GoalStep';
import InformationStep, { validateInformationStep } from './InformationStep';
import KeywordsStep, { validateKeywordsStep } from './KeywordsStep';
import LocationStep, { validateLocationStep } from './LocationStep';
import { getSelectedTargetables } from './LocationStep/LocationStep';
import ReviewStep from './ReviewStep';

import * as styles from './GoogleAdsCampaignEdit.css';

interface IGoogleAdsCampaignEditProps {
  campaign: GoogleAdsSmartCampaign;
}

interface IValidateGoogleAdsCampaignProps {
  campaign: GoogleAdsSmartCampaign;
  targetings: ListResponse<Targeting>;
  showCallButton: boolean;
  creatives: ListResponse<GoogleAdsCreative>;
}

function validate({
  campaign,
  targetings,
  showCallButton,
  creatives,
}: IValidateGoogleAdsCampaignProps): ICampaignValidationErrors {
  const information = validateInformationStep(campaign);
  const location = validateLocationStep(targetings.models);
  const keywords = validateKeywordsStep(campaign);
  const adText = validateAdTextStep(campaign, showCallButton, creatives);
  const goal = validateGoalStep(campaign);
  const budget = flattenErrors(validateBudgetStep(campaign));

  return {
    information,
    location,
    keywords,
    adText,
    goal,
    budget,
  };
}

function GoogleAdsCampaignEdit({ campaign }: IGoogleAdsCampaignEditProps): JSX.Element {
  const { Creatives, Targetings, Targetables } = useStore();
  const { t } = useTranslation();
  const [showCallButton, toggleShowCallButton] = useToggle(!!campaign.get('phone_number'));

  const targetings = Targetings.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
    // set to 1000 to be safely more than the number of location targetings
    pagination: { page_size: 1000 },
  });

  const creatives = Creatives.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  }) as ListResponse<GoogleAdsCreative>;

  const getStepErrors = useCallback(
    (step: number): string[] => {
      // If we're loading the wizard, return no errors
      if (step === -1) {
        return [];
      }
      const map = {
        0: flattenErrors(validateInformationStep(campaign)),
        1: validateLocationStep(targetings.models),
        2: flattenErrors(validateKeywordsStep(campaign)),
        3: flattenErrors(validateAdTextStep(campaign, showCallButton, creatives)),
        4: flattenErrors(validateGoalStep(campaign)),
        5: flattenErrors(validateBudgetStep(campaign)),
        6: [],
      };
      return map[step];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showCallButton],
  );

  const getCompletedStepMap = (): IWizardStepsCompleted => {
    return {
      0: !getStepErrors(0).length,
      1: !getStepErrors(1).length,
      2: !getStepErrors(2).length,
      3: !getStepErrors(3).length,
      4: !getStepErrors(4).length,
      5: !getStepErrors(5).length,
      6: !getStepErrors(6).length,
    };
  };

  // Allows the wizard to know when it is ready to move to the next step
  const waitFor: () => boolean = () => {
    return !(campaign.isPending || targetings.isPending || creatives.isPending);
  };

  const { currentStep, completeStep, onNext, onPrev, onChangeStep } = useWizardState({
    getCompletedStepMap,
    waitFor,
  });

  const steps = (
    <Steps
      completed={campaign.get('state') === CampaignState.Published ? 6 : completeStep}
      current={currentStep}
      onChange={handleChangeStep}
      usePortal={true}
    >
      <Step key={1} stepIndex={0} title={t('Information')} />
      <Step key={2} stepIndex={1} title={t('Location')} />
      <Step key={4} stepIndex={2} title={t('Keywords')} />
      <Step key={3} stepIndex={3} title={t('Ad Text')} />
      <Step key={6} stepIndex={4} title={t('Goal')} />
      <Step key={5} stepIndex={5} title={t('Budget')} />
      <Step key={7} stepIndex={6} title={t('Review')} />
    </Steps>
  );

  const currentStepHasErrors = !!getStepErrors(currentStep).length;

  async function handleChangeStep(step: number): Promise<void> {
    if (currentStep === 0) {
      await campaign.patchDirty();
    }
    onChangeStep(step);
  }

  async function handleNextStep(): Promise<void> {
    if (currentStep === 0) {
      await campaign.patchDirty();
    }
    onNext();
  }

  function validateButton(): ICampaignValidationErrors {
    return validate({ campaign, targetings, showCallButton, creatives });
  }

  const childModels: Model[] = [
    ...getSelectedTargetables(targetings.models, Targetables),
    ...targetings.models,
    ...creatives.models,
  ];

  const publishButton = (
    <SaveCampaignButton
      campaign={campaign}
      childModels={childModels}
      key={'publish'}
      name={'publish_or_stop'}
      shouldChangeState={true}
      validate={validateButton}
    />
  );

  return (
    <Wizard className={styles.wizard} isFullWidth={true} layout={'horizontal'} steps={steps}>
      <ActionBar
        left={
          <SaveCampaignButton
            campaign={campaign}
            childModels={childModels}
            key={'save'}
            name={'save_changes'}
            validate={
              campaign.get('state') === CampaignState.Published ? validateButton : undefined
            }
          />
        }
        right={
          <>
            <Button disabled={currentStep === 0} onClick={onPrev}>
              {t('Previous')}
            </Button>
            {currentStep === 6 && publishButton}
            {currentStep !== 6 && (
              <ButtonValid
                disabled={currentStep === 6 || currentStepHasErrors}
                errors={getStepErrors(currentStep)}
                name={'next_step'}
                onClick={handleNextStep}
              >
                {t('Next')}
              </ButtonValid>
            )}
          </>
        }
        usePortal={true}
      />
      <section className={styles.main}>
        {currentStep === 0 && <InformationStep campaign={campaign} />}
        {currentStep === 1 && <LocationStep campaign={campaign} targetings={targetings.models} />}
        {currentStep === 2 && <KeywordsStep campaign={campaign} />}
        {currentStep === 3 && (
          <AdTextStep
            campaign={campaign}
            creatives={creatives}
            showCallButton={showCallButton}
            toggleShowCallButton={toggleShowCallButton}
          />
        )}
        {currentStep === 4 && <GoalStep />}
        {currentStep === 5 && <BudgetStep campaign={campaign} />}
        {currentStep === 6 && (
          <ReviewStep
            campaign={campaign}
            creatives={creatives}
            setCurrentStep={onChangeStep}
            targetings={targetings.models}
          />
        )}
      </section>
    </Wizard>
  );
}

export default observer(GoogleAdsCampaignEdit);
